.Contact .contact-content {
    display: flex;
}
.Contact .contact-text {
    padding-top: 2.5rem;
}
  
.Contact .container {
    padding-bottom: 3rem;
    flex: calc(50%);
    padding-left: 7rem;
}

.Contact .icons {
    padding-bottom: 1.5rem;
}
  
.Contact .icons .icon {
    color: var(--secondary);
}

.contact-content .btn-port {
    color: var(--secondary);
    font-size: 1.2rem;
    transition: none;
}

.contact-content .btn-port:hover {
    color: #fff;
    border: none;
    background-color: var(--primary);
}

.contact-content .btn-port a {
    text-decoration: none;
    color: var(--secondary);
}


.Contact .contact-bg {
    flex: calc(50%);
    background: url('../../img/contactBg.png') center center/cover no-repeat;
}

@media (max-width: 768px) {
    .Contact .container {
        padding-left: 3rem;
    }
}

@media (max-width: 414px) {
    .Contact .contact-content {
        display: block;
    }

    .Contact .container {
        padding-left: 1.5rem;
        padding-bottom: 0;
    }

    .Contact .contact-bg {
        height: 50vh;
    }
}
  