:root {
    --primary: #E8A167;
    --secondaryOrange: #c47230; 
}

.hero-container {
    background: url('../../img/bgImage.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color:var(--secondaryOrange);
    font-size: 100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    width: 60%;
    text-align: center;
    line-height: 2rem;
}

.icon {
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

.icons .icon-holder:not(:last-child) {
    margin-right: 1.5rem;
}

.icons .icon {
    font-size: 2.5rem;
    color: var(--primary);
}

@media (min-width: 960px) {
    .hero-container > p {
        width: 50%;
    }
}

.Typewriter {
    padding-top: 5rem;
}

span.Typewriter__wrapper {
    color: #fff;
    letter-spacing: 0.5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
}

span.Typewriter__cursor {
    color: #fff;
    font-size: 1.6rem;
}

.hero-btn {
    padding-top: 1.5rem;
}

@media (max-width: 768px) {
    .hero-container {
        height: 60vh;
    }

    .Typewriter {
        padding-top: 0;
    }

    span.Typewriter__wrapper {
        font-size: 1rem;
    }

    .hero-container > h1 {
        font-size: 50px;
    }

    .hero-container > p {
        width: 80%;
        font-size: 14px;
    }

    .hero-btn {
        padding-bottom: 1rem;
    }

    .icons .icon {
        font-size: 2rem;
    }
}

@media (max-width: 320px ) {
    span.Typewriter__wrapper {
        font-size: 0.6rem;
    }
    .hero-container > h1 {
        font-size: 45px;
    }

    .hero-container > p {
        font-size: 0.8rem;
        line-height: 1.5rem;
    }

    .hero-btn {
        padding-top: 1rem;

    }
}