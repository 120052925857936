:root {
    --primary: #E8A167;
    --secondaryOrange: #c47230; 
}

.Title {
    font-size: 2rem;
    color: var(--secondaryOrange);
    position: relative;
    letter-spacing: 2px;
}


.Title h3 {
    padding-top: 8rem;
    font-family: 'Sacramento', cursive;
}

.Title::before {
    content: "";
    bottom: -0.5rem;
    position: absolute;
    left: 0;
    background-color: #e8a1676e;
    width: 8rem;
    height: 0.4rem;
}

.Title::after {
    content: "";
    bottom: -0.5rem;
    position: absolute;
    left: 0;
    background-color: var(--primary);
    width: 4rem;
    height: 0.4rem;
}

.Title span {
    font-family: 'PT Sans Narrow', sans-serif;
    color: var(--primary);
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 5rem;
    opacity: 0.2;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .Title h3 {
        padding-top: 4rem;
        font-size: 1.4rem;
    }

    .Title span {
        font-size: 2.87rem;
    }
}

@media (max-width: 320px ) {

}