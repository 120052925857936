:root {
    --primary: #E8A167;
    --secondary: #202123;
}

.btn {
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    border: none;
}

.btn:hover {
    transition: all 0.3s ease-in-out;
    background: #000;
    color: #fff;
}

.btn--primary {
    background-color: var(--primary);
    color: var(--secondary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 2px solid #c47230;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 16px;
}

.btn--large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 18px;
}

.btn--outline:hover {
    background-color:#c47230;
    color: var(--secondary);
    transition: all 0.3s ease-out;
}

.btn--primary:hover {
    background-color: var(--secondary);
    color: var(--primary);
}