.Portfolio {
    background-color: var(--secondary);
}

.work {
    padding-top: 5rem;
}

.cat-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2rem 4rem;
    flex-wrap: wrap;
}

.btn-port {
    padding: 0.8rem 3rem;
    background-color: transparent;
    border: 2px solid var(--secondaryOrange);
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 1rem;
    margin-bottom: 2rem;
    transition: all 0.3s ease-in-out;
}

.btn-port:not(:last-child) {
    margin-right: 2rem;
}

.cat-menu .btn-port:hover,  .cat-menu .btn-port:active, .cat-menu .btn-port:focus {
    border: none;
    background-color: var(--primary);
    color: var(--secondary);
}

.portfolio-menu {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1;
}

.portfolio-item {
    padding-bottom: 2rem;
}

.image-container {
    position: relative;
    overflow: hidden;
    max-height: 400px;
}

.portfolio-item .image-container img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: transform .5s ease;
}

.image-container:hover img {
    transform: scale(1.5);
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
}

.image-container:hover .overlay {
    opacity: 0.9;
}

.text {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 85%;
}

.text h4 {
    color: var(--secondaryOrange);
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 3px solid rgb(175, 175, 175);
}

.text p {
    padding-bottom: 20px;
}

.text a {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border: 3px solid var(--secondaryOrange);
    border-radius: 5px;
}

.text a:hover {
    background-color: var(--secondaryOrange);
}

.portfolio-item h5 {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--secondaryOrange);
}


.portfolio-item h5:hover {
    color: var(--primary);
}

@media (max-width: 1024px ) {
    .portfolio-menu {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 414px) {
    .work {
        padding-top: 3rem;
    }

    .cat-menu {
        margin: 0 auto;
    }

    .btn-port:not(:last-child) {
        margin-right: 0.5rem;
    }

    .btn-port {
        padding: 0.8rem 2rem;
    }

    .portfolio-menu {
        display: block;
    }

    .portfolio-item .image-container img {
        height: 250px;
    }

    .text {
        width: 80%;
    }

    .text a {
        padding: 5px 15px;
    }

}

