:root {
  --primary: #E8A167;
  --secondary: #202123;
}

 
* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

html {
  height: 100%;
}

body {
  font-family: 'PT Sans', sans-serif;
  height:100%;  
}

h1, h2, h3, h4, h5 {
  font-family: 'PT Sans Narrow', sans-serif;
}

p {
  line-height: 2rem;
}

.container {
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
  padding: 0 3rem;
  padding-bottom: 8rem;
}

.title {
  text-transform: uppercase;
  font-size: 3rem;
  color: var(--secondary);
  position: relative;
  padding-left: 10rem;
}

.title::after {
  content: "";
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 6rem;
  height: .2rem;
  margin-left: 2rem;
  background-color: var(--primary);
}


@media (max-width: 768px) {
  .container {
    padding: 0 1.5rem;
    width: 100%;
  }

  .title {
    font-size: 3.5rem;
  }

  .title::after {
    width: 10rem;
    margin-left: -2rem;
  }
}
