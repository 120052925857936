.NavbarItems {
    background-color: #202123;
    height: 80px; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: -1px;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1100px;
}
.navbar-logo {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 1.6rem;
    color: #E8A167;
    margin-left: 20px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    cursor: pointer;
    text-decoration: none;
}

.nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 79px;
    left: -100%;
    opacity: 1;
    transition: 0.3s ease-in-out;
} 

@media (min-width: 835px) {
    .nav-menu {
        position: absolute;
        top: 80px;
    }
}

.nav-menu.active {
    background: rgba(10, 10, 10, 0.95);
    left: 0;
    opacity: 1;
    transition: 0.3s ease-in-out;
    z-index: 1;
} 

.nav-links {
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
    cursor: pointer;
}

.nav-links.active {
    border-bottom: 3px solid #E8A167;
    height: 80%;
}

.nav-links:hover {
    color: #E8A167;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
}

.fa-bars, .fa-times {
    color :#fff;
}

.menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
}

.nav-item.active {
    border-bottom: 1px solid #fff;
}


@media (min-width: 960px) {

    .navbar-logo {
        justify-self: start;
    }

    .nav-menu {
        display: grid;
        grid-template-columns: repeat(4, auto);
        gap: 10px;
        list-style: none;
        text-align: center;
        width: 60vw;
        justify-content: end;
        margin-right: 2rem;
        top: initial;
        left: initial;
        height: initial;
        align-items: center;
    }

    .nav-item {
        height: 80px;
    }

    .nav-links {
        text-align: initial;
        display: flex;
        padding: 1.5rem;
        height: 100%;
        text-transform: uppercase;

    }

    .menu-icon {
        display: none;
    }
}


