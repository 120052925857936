.About{
    background-color: rgb(221, 221, 221);
}

.about-flex {
    display: flex;
    padding-top: 3rem;
}

.image img {
    max-height: 550px;
    width: auto;
}

.image {
    flex: 0 0 50%;
}

.about-content {
    flex: 0 0 50%;
    padding-left: 1rem;
}

.about-content h2 {
    font-size: 48px;
}

.about-content .skills {
    display: flex;
    flex-wrap: wrap;
    
}

.about-content .skills .AboutSkills {
    flex: 0 0 50%;
    padding-bottom: 2rem;
    padding-top: 2rem;
    align-items: center;
}

.about-content .skills .AboutSkills:nth-of-type(odd) {
    padding-right: 3rem;
}

/** Timeline **/
.Timeline {
    padding-top: 5rem;
}
.vertical-timeline::before {
    background: var(--primary)!important;
}

#description {
    margin: 1.5rem 0 2rem 0;
}

 @media (max-width: 1024px) {
    .about-content .skills .AboutSkills:nth-of-type(even) {
        padding-left: 3rem;
    }

    .image {
        flex: 0 0 25%;
    }

    .image img {
        max-height: 350px;
    }

    .about-content {
        flex: 0 0 60%;
    }
}

@media (max-width: 834px) {
    .about-flex {
        display: block;
        padding-top: 2rem;
        text-align: center;
    }

    .image img {
        max-height: 250px;
    }

    .about-content {
        padding-left: 0;
    }

    .about-content p {
        font-size: 14px;
    }

    .about-content h2 {
        font-size: 35px;
    }

    .AboutSkills h3 {
        padding-left: 2rem;
        font-size: 14px;
    }

    .Timeline {
        padding-top: 2rem;
    }

    .About {
        padding-bottom: 2rem;
    }
} 

