:root {
    --primary: #E8A167;
    --secondaryOrange: #c47230; 
    --secondary: #202123;
}

.AboutSkills {
    display: flex;
}

.square {
    height: 60px;
    width: 60px;
    background-color: var(--primary);
    opacity: 0.5;
    border-radius: 5px;
}

.AboutSkills h3 {
    position: absolute;
    padding-left: 3rem;
    color: var(--secondary);
}


